@import '~antd/dist/antd.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.layout {
  min-height: 100%;
  display: grid;
  grid-template-rows: 64px auto 69px;
}

.logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px 24px 16px 0;
  float: left;
}

.ant-layout-header .ant-menu-item {
  font-weight: 700;
}

.ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}

.ant-radio-group {
  margin-bottom: 15px;
  width: 100%;
}

.center {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.urow {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
  padding: 0;
}

/* Create four equal columns that sits next to each other */
.ucolumn {
  -ms-flex: 25%; /* IE10 */
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
}

.ucolumn:first-child {
  padding-left: 0px
}

.ucolumn:last-child {
  padding-right: 0px
}

.ucolumn img, .ucolumn video, .ucolumn audio {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .ucolumn {
    -ms-flex: 50%;
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .ucolumn {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
    padding: 0px;
  }
}